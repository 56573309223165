<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    欢迎来到资产管理系统! 👋🏻
                  </p>
                  <p class="mb-2">
                    登录账号并开始管理项目
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import axios from '@axios'
import { modalFail } from '@core/utils/prompt'
import { initialAbility } from '@/plugins/acl/config'
import Swal from 'sweetalert2'
import config from '../../config'

export default {
  setup() {
    // Template Ref
    const { router } = useRouter()
    const vm = getCurrentInstance().proxy

    const backUrl = router.currentRoute.query.back ? router.currentRoute.query.back : `${window.location.origin}`
    const ticketStr = router.currentRoute.query.ticket

    const goSsoAuthUrl = () => {
      useJwt.ssoAuthUrl({ clientLoginUrl: window.location.href, backUrl })
        .then(response => {
          console.log(response.data.data)

          // 重定向到SSO登入页面
          window.location.href = response.data.data
        })
    }
    const doLoginByTicket = item => {
      console.log(item)
      useJwt.login(`ticket=${item}`)
        .then(response => {
          // const { accessToken } = response.data
          console.log(response.data)

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', response.data.data)
          console.log('doLoginByTicket::',localStorage.getItem('accessToken'))

          return response
        })
        .then(result => {
          console.log(result)
          axios.get(`${config.userInfoEndpoint}`)
            .then(response => {
              console.log(response)

              // const { ability: userAbility } = response.data.data

              // Set user ability
              // ? https://casl.js.org/v5/en/guide/intro#update-rules
              vm.$ability.update(response.data.data.ability)

              // Set user's ability in localStorage for Access Control
              localStorage.setItem('userAbility', JSON.stringify(response.data.data.ability))

              // We will store `userAbility` in localStorage separate from userData
              // Hence, we are just removing it from user object
              // delete user.ability

              // Set user's data in localStorage for UI/Other purpose
              localStorage.setItem('userData', JSON.stringify(response.data.data))

              // On success redirect to home 直接重定向到返回页面
              // router.push({ name: 'dashboard-today' }).catch(err => (
              //   console.log(err)
              // ))
              window.location.href = backUrl
            }).catch(error => {
            // DONE: Next Update - Show notification
              console.log('error :>> ', error.response, error)
              Swal.fire({
                title: '失败！',
                confirmButtonText: '重新登录',
                icon: 'error',
                html: error.response.data.message,
              }).then(() => {
              // 发送退出事件
                useJwt.logout().then(() => {
                // Remove userData from localStorage
                // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                  localStorage.removeItem('accessToken')

                  // Remove userData & Ability from localStorage
                  localStorage.removeItem('userData')
                  localStorage.removeItem('userAbility')

                  // Reset ability
                  vm.$ability.update(initialAbility)

                  // Redirect to login page
                  // setTimeout(() => router.push({ name: 'auth-login' }), 3000)
                  router.push({ name: 'auth-login' })
                }).catch(e => {
                // DONE: Next Update - Show notification
                  console.error('Oops, Unable to logout!')
                  console.log('error :>> ', e.response)

                  modalFail(e.response.data.message)
                })
              })
            })
        })
        .catch(error => {
          // DONE: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response, error)

          // modalFail(error.response.data.message)

          // 有问题重新定向
          // goSsoAuthUrl()
        })
    }

    if (ticketStr) {
      doLoginByTicket(ticketStr)
    } else {
      goSsoAuthUrl()
    }

    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
